import * as React from "react"

import {
  Amplify,
  AuthProvider,
  UserManagementService,
  setupCognitoStorageToCookies,
  useAuth,
} from "@behaviour-lab/blab-component-library/auth"
import { Toaster } from "@behaviour-lab/blab-component-library/components"
import PolygonLogoIcon from "@behaviour-lab/blab-component-library/icons/logos/PolygonLogoIcon"
import { CookieStorage } from "aws-amplify/utils"
import { AppProps } from "next/app"
import { Inter } from "next/font/google"
import { useRouter } from "next/router"
import { ThemeProvider } from "next-themes"
import { useSWRConfig } from "swr"

import StaffToolbar from "src/components/staff-toolbar"
import { DebugContextProvider } from "src/context/debug-context"
import { sessionStorageManager } from "src/lib/storage-manager"

import "@behaviour-lab/blab-component-library/dist/style.css"
import "src/styles/globals.css"

const inter = Inter({ subsets: ["latin"] })

// redirect_uri should be localhost if running npm run dev, and polygon.[env].behaviourlab.com if running on deployed version
const REDIRECT_URI =
  process.env.REDIRECT_URI || (process.env.NEXT_PUBLIC_REDIRECT_URI as string)

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: process.env.NEXT_PUBLIC_COGNITO_POOL_ID as string,
      userPoolClientId: process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID as string,
      loginWith: {
        oauth: {
          domain: process.env.NEXT_PUBLIC_OAUTH_DOMAIN as string,
          scopes: ["email", "openid", "profile"],
          redirectSignIn: [REDIRECT_URI],
          redirectSignOut: [REDIRECT_URI],
          responseType: "code",
        },
        username: true,
        email: false,
        phone: false,
      },
    },
  },
})

setupCognitoStorageToCookies(
  new CookieStorage({
    domain:
      process.env.NODE_ENV === "development"
        ? "localhost"
        : (process.env.NEXT_PUBLIC_COOKIE_STORAGE_DOMAIN as string),
    expires: 0.25,
  }),
)

/**
 * This is a custom component that is used to initialize pages.
 * It can be overwritten and control the page initialisation to
 * - persist layout between page changes
 * - keep state when navigating pages
 * - implement custom error handling
 * - inject additional data into pages
 * - add global CSS
 * */
function App(props: AppProps) {
  const router = useRouter()
  const { mutate } = useSWRConfig()

  const handleClear = () => {
    // Clear browser's session storage on logout
    // Browser's local storage is not supposed to be cleared on logout
    sessionStorageManager.clear()

    // Clear all SWR Cache
    mutate(
      () => true, // Leaving this blank clears all keys
      undefined, // update cache data to `undefined`
      { revalidate: false }, // do not revalidate
    )
    router.push("/")
  }

  const handleLoginWithSSO = async (email: string) => {
    const splittedEmail = email.split("@")
    const domain = splittedEmail[1]

    const response = await fetch(
      `${process.env.NEXT_PUBLIC_CRUD_API}/idp/${domain}`,
    )
    if (!response.ok) {
      throw Error("Identity provider not retrieved")
    }

    const responseData = await response.json()

    if (!responseData.data) {
      throw Error("Identity provider not parsed from response")
    }

    await UserManagementService.signInWithRedirect({
      provider: {
        custom: responseData.data,
      },
    })
  }

  return (
    <div className={inter.className}>
      <AuthProvider
        onLogout={handleClear}
        productName="Polygon"
        productLogo={<PolygonLogoIcon width={56} height={56} />}
        onLoginWithSSO={handleLoginWithSSO}
      >
        <InsideAuthProvider {...props} />
      </AuthProvider>
    </div>
  )
}

const InsideAuthProvider = ({ Component, pageProps }: AppProps) => {
  const { user } = useAuth()

  return (
    <ThemeProvider attribute="class" forcedTheme="light" enableSystem={false}>
      <DebugContextProvider>
        <Component {...pageProps} />
        <Toaster />
      </DebugContextProvider>
      <StaffToolbar user={user} />
    </ThemeProvider>
  )
}

export default App
